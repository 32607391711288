
import { Component, Vue /* Ref */ } from 'vue-property-decorator';
import { getStay } from '@/api/stay';
import $ from 'jquery';
import {
  getLat,
  getLng,
  setLat,
  setLng,
  // setStayReservation,
} from '@/utils/cookies';
import moment from 'moment';
import { getStayRoomList } from '@/api/stayRoom';
import Calendar from '@/components/Calendar/index.vue';
// import { getReservationItem } from '@/api/reservationItem';
import { addRecentList } from '@/utils/localStorage';
import Slick from 'vue-slick';
import { addViewCount } from '@/api/hits';
import InfiniteLoading from 'vue-infinite-loading';
import {
  getReservationReviewList,
  // getReservationReviewLikeList,
  getReservationReviewMypageTotal,
  // getReservationReviewOwnList,
  // deleteReservationReview,
} from '@/api/reservationReview';
import { likeReview } from '@/api/reservationReviewLike';
import { Stay } from './stay';

@Component({
  components: {
    Calendar,
    Slick,
    InfiniteLoading,
  },
})
export default class extends Vue {
  mounted() {
    // this.initKakao();
    this.getStay();
    this.getStayRoomList();
    this.getReviewList();
    this.slickLoading();
    this.vaildateDate();
  }

  private vaildateDate() {
    if (this.formQuery.startDate === this.formQuery.endDate) {
      this.$message.error('시작일과 종료일은 같을 수 없습니다.');
      this.$router.push({ name: 'StayIndex' });
    }
  }

  private slickLoad = false;

  private isLoading = true;

  private slickLoading() {
    // console.log('시작');
    this.isLoading = true;
    window.setTimeout(() => {
      this.isLoading = false;
      this.slickLoad = true;
      // console.log('종료');
    }, 3000);
  }

  private handleDialogOpen() {
    this.datePickerVisible = true;
    setTimeout(() => {
      const calendarRef: any = this.$refs.calendar;
      if (calendarRef) calendarRef.setDate(new Date(this.formQuery.startDate), new Date(this.formQuery.endDate));
      this.calendarData = [];
    }, 100);
    // this.handleResetFormQuery();
  }

  private noScr() {
    document.documentElement.classList.add('noscr');
  }

  private scr() {
    document.documentElement.classList.remove('noscr');
  }

  private transitionOn = true;

  private cardArray = [];// 슬라이드 카드가 담길 배열 mounted에서 담아준다.

  private slideCoord = 0;// 옆으로 움직일 값, (슬라이드의 마진,패딩값 포함된 width)

  private slideWidth = 340;

  private slideCount = 5;// 전체 슬라이드 개수

  private currentSlide = 0;

  private stayOptionList = [];

  private stayDetail = [];

  private slides(data : any) {
    this.stayOptionList = data.options;
    // this.stayOptionList = data;
    // for (let i = 0; i < data.length; i += 1) {
    //   result.push(data.options);
    // }
    // console.log(result);
    // return result;
  }

  private stay = {
    name: '',
    introduction: '',
    images: [],
    tags: [],
    options: [],
    remainRoom: 0,
    inquiryTel: '',
    address: '',
    addressDetail: '',
    checkIn: '',
    checkOut: '',
    usageInstruction: '',
    parkingInfo: '',
    surroundInfo: '',
    additionalPeopleInfo: '',
    refundPolicyInfo: '',
    confirmationInfo: '',
    minPrice: '',
    maxPrice: '',
    lat: '',
    lng: '',
    avgScore: '0.0',
    reviewCount: 0,
    business: {
      idx: null,
      bossName: '',
      companyName: '',
      companyAddress: '',
      companyAddressDetail: '',
      greeting: '',
      profileFileUid: '',
    },
    certMarkStatus: 0,
  }

  private staySplit = {
    introduction: [] as Object[],
    parkingInfo: [] as Object[],
    surroundInfo: [] as Object[],
    additionalPeopleInfo: [] as Object[],
    refundPolicyInfo: [] as Object[],
    confirmationInfo: [] as Object[],
  }

  private formQuery: any = {
    stayIdx: this.$route.params.stayIdx,
    lat: '37.566826',
    lng: '126.9786567',
    startDate: this.$route.query.startDate ? this.$route.query.startDate : moment().format('YYYY-MM-DD'),
    endDate: this.$route.query.endDate ? this.$route.query.endDate : moment().add(1, 'days').format('YYYY-MM-DD'),
    adult: this.$route.query.adult ? this.$route.query.adult : 2,
    child: this.$route.query.child ? this.$route.query.child : 0,
  }

  private detailQuery: any = {
    startDate: this.$route.query.startDate ? this.$route.query.startDate : moment().format('YYYY-MM-DD'),
    endDate: this.$route.query.endDate ? this.$route.query.endDate : moment().add(1, 'days').format('YYYY-MM-DD'),
    adult: this.$route.query.adult ? this.$route.query.adult : 2,
    child: this.$route.query.child ? this.$route.query.child : 0,
  }

  private currentIndex = 0;

  private reservationItem = [];

  private map = null;

  private curTabs: number[] = [1, 2, 3, 4];

  private toggleSection(section: number): void {
    const index = this.curTabs.indexOf(section);
    if (index !== -1) {
      // 섹션이 이미 열려있는 경우 닫기
      this.curTabs.splice(index, 1);
    } else {
      // 새로운 섹션 열기
      this.curTabs.push(section);
    }

    // 아이콘 클래스 업데이트
    for (let i = 1; i <= 4; i += 1) {
      const icon = this.$refs[`icon${i}`] as HTMLElement;
      const isOpen = this.curTabs.includes(i);
      if (isOpen) {
        icon.classList.remove('el-icon-arrow-down');
        icon.classList.add('el-icon-arrow-up');
      } else {
        icon.classList.remove('el-icon-arrow-up');
        icon.classList.add('el-icon-arrow-down');
      }
    }
  }

  private isSectionVisible(section: number): boolean {
    return this.curTabs.includes(section); // 현재 섹션의 가시성 여부 반환
  }

  private imgUrl = process.env.VUE_APP_IMG_URL;

  private apiUrl = process.env.VUE_APP_COMMON_API;

  private handleClickAccomodationSlide(str: string) {
    const slick = (this.$refs.accomodationBanner as any);
    if (str === 'next') {
      slick.next();
    } else {
      slick.prev();
    }
  }

  private datePickerVisible = false;

  private locationVisible = false;

  private filterOptionVisible = false;

  private reserveRoom1 = false;

  private reserveRoom2 = false;

  private reserveRoom3 = false;

  private loading = true;

  private roomLoading = true;

  private roomList = [];

  private reviewList = [];

  private mainslickOptions = {
    autoplay: true,
    autoplaySpeed: 4000,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    speed: 400,
    prevArrow: $('.iconslick-prev'),
    nextArrow: $('.iconslick-next'),
    draggable: true,
    pauseOnHover: true,
  };

  private slickOptions = {
    autoplay: true,
    autoplaySpeed: 4000,
    infinite: true,
    slidesToShow: 5,
    slidesToScroll: 1,
    speed: 400,
    prevArrow: $('.iconslick-prev'),
    nextArrow: $('.iconslick-next'),
    draggable: true,
    pauseOnHover: true,
    responsive: [
      {
        breakpoint: 960,
        settings: {
          slidesToShow: 4,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 500,
        settings: {
          slidesToShow: 3,
        },
      },
    ],
  };

  private reviewQuery = {
    page: 0,
    size: 10,
    stayIdx: this.$route.params.stayIdx,
  }

  private reviewNoMore = false;

  private review = {
    propileName: '루피재욱',
    propileAmount: '55',
    propilePhoto: '15',
    rate: '4.0',
    likeCount: 0,
  }

  private entrepreneur = {
    propileName: '오재욱',
  }

  private informationStatus = '';

  private active = '';

  private formattedBasicInfo(stay: Stay) {
    if (stay) {
      this.staySplit.introduction = stay.introduction ? stay.introduction.split('\n').filter(Boolean) : [''];
      this.staySplit.parkingInfo = stay.parkingInfo ? stay.parkingInfo.split('\n').filter(Boolean) : ['입력된 정보가 없습니다'];
      this.staySplit.surroundInfo = stay.surroundInfo ? stay.surroundInfo.split('\n').filter(Boolean) : ['입력된 정보가 없습니다'];
      this.staySplit.additionalPeopleInfo = stay.additionalPeopleInfo ? stay.additionalPeopleInfo.split('\n').filter(Boolean) : ['입력된 정보가 없습니다'];
      this.staySplit.refundPolicyInfo = stay.refundPolicyInfo ? stay.refundPolicyInfo.split('\n').filter(Boolean) : ['입력된 정보가 없습니다'];
      this.staySplit.confirmationInfo = stay.confirmationInfo ? stay.confirmationInfo.split('\n').filter(Boolean) : ['입력된 정보가 없습니다'];
    }
  }

  private handleClickSlick(refName: string, type: string) {
    if (this.$refs[refName]) {
      const reference: any = this.$refs[refName];
      if (type === 'prev') reference.prev();
      if (type === 'next') reference.next();
    }
  }

  private initKakao() {
    if (!(window as any).kakao || (window as any).kakao.maps) {
      const script = document.createElement('script');
      script.src = `${window.location.protocol}//dapi.kakao.com/v2/maps/sdk.js?appkey=${process.env.VUE_APP_KAKAO_CLIENT_ID}&libraries=services`;
      document.head.appendChild(script);
    }
  }

  private handleInformationMore() {
    this.informationStatus = '--open';
    this.informationStatus = 'expanded';
  }

  private getStay() {
    const lat = getLat();
    const lng = getLng();
    if (lat && lng) {
      this.formQuery.lat = lat;
      this.formQuery.lng = lng;
      this.getStayDetail();
    } else {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          this.formQuery = {
            ...this.formQuery,
            lat: position.coords.latitude ? position.coords.latitude : '34.81762395218535',
            lng: position.coords.longitude ? position.coords.longitude : '127.92101314625813',
          };
          setLat(position.coords.latitude);
          setLng(position.coords.longitude);
          this.getStayDetail();
        },
        (error) => {
          this.getStayDetail();
        },
      );
    }
  }

  private getStayDetail() {
    getStay(this.$route.params.stayIdx, this.formQuery).then((res) => {
      this.loading = false;
      this.stay = res.data;
      this.slides(res.data);
      addRecentList(res.data, window.location.pathname);
      this.formattedBasicInfo(this.stay);
    }).catch(() => {
      this.$message.error('숙소정보를 불러오는데 실패했습니다.');
      this.loading = false;
      this.$router.push({ name: 'StayIndex' });
    }).then(() => {
      addViewCount({ stayIdx: this.$route.params.stayIdx });
    });
  }

  private initMap(lat: any, lng: any) {
    const container = document.getElementById('map');
    const option = {
      center: new (window as any).kakao.maps.LatLng(lat, lng),
      level: 3,
    };
    this.map = new (window as any).kakao.maps.Map(container, option);
    const markerPosition = new (window as any).kakao.maps.LatLng(lat, lng);
    const marker = new (window as any).kakao.maps.Marker({
      position: markerPosition,
    });
    marker.setMap(this.map);
    const content = `<div class="gis-item">${this.stay.name}</div>`;
    const customOverlay = new (window as any).kakao.maps.CustomOverlay({
      map: null,
      position: markerPosition,
      content,
      yAnchor: 2.4,
      visible: false,
    });
    (window as any).kakao.maps.event.addListener(marker, 'mouseover', () => {
      customOverlay.setMap(this.map);
    });
    (window as any).kakao.maps.event.addListener(marker, 'mouseout', () => {
      customOverlay.setMap(null);
    });
    (window as any).kakao.maps.event.addListener(marker, 'click', () => {
      (window as any).open(`https://map.kakao.com/link/map/${this.stay.name},${lat},${lng}`);
    });
  }

  private handleViewLocation() {
    if (this.stay.lat && this.stay.lng) {
      this.locationVisible = !this.locationVisible;
      this.$nextTick(() => {
        this.initMap(this.stay.lat, this.stay.lng);
      });
    } else {
      this.$message.error('지도를 불러오는데 실패했습니다.');
    }
  }

  private getStayRoomList() {
    this.roomLoading = true;
    getStayRoomList(this.formQuery).then((res) => {
      console.log(res);
      this.roomLoading = false;
      this.roomList = res.data;
    });
  }

  private getFewDays(start: any, end: any) {
    let fewDays = 1;
    const startDate = moment(start, 'YYYY-MM-DD');
    const endDate = moment(end, 'YYYY-MM-DD');
    fewDays = endDate.diff(startDate, 'days');
    if (Number.isNaN(fewDays)) {
      fewDays = 0;
    }

    return fewDays;
  }

  private handleResetFormQuery() {
    this.detailQuery = {
      adult: 2,
      child: 0,
      startDate: moment().format('YYYY-MM-DD'),
      endDate: moment().add(1, 'days').format('YYYY-MM-DD'),
    };
  }

  private handleApplyFormQuery() {
    this.formQuery = {
      ...this.formQuery,
      adult: this.detailQuery.adult,
      child: this.detailQuery.child,
      startDate: this.detailQuery.startDate,
      endDate: this.detailQuery.endDate,
    };
    this.datePickerVisible = false;
    this.getStayRoomList();
  }

  private getReviewList() {
    getReservationReviewList(this.reviewQuery).then((res) => {
      this.reviewList = this.reviewList.concat(res.data.content);
      this.reviewNoMore = res.data.last;
    });
  }

  private handleMoreReviewList() {
    this.reviewQuery.page += 1;
    this.getReviewList();
  }

  private calendarData = []; // 달력 일별 데이터

  private selectedDay: string | null = '';

  private selectedMonth = moment().format('YYYY-MM');

  private calendarLoading = false;

  private handleChangeMonth(yearMonth: any) {
    this.selectedMonth = moment(yearMonth).format('YYYY-MM');
    this.calendarData = [];
    // this.getReservationCalendar();
  }

  private handleChoiceDate(startDate: string, endDate: string) {
    // this.selectedHourUid = '';
    this.selectedDay = startDate;
    this.detailQuery.startDate = startDate;
    this.detailQuery.endDate = endDate;
    // if (this.reservationItem.settingUnitStatus === 0) {
    // this.getReservationItemDay();
    // } else if (this.reservationItem.settingUnitStatus === 1) {
    // this.getReservationItemHour();
    // }
  }

  private shareOpen() {
    this.$confirm('공유하시겠습니까?', ' ', {
      confirmButtonText: '네',
      cancelButtonText: '아니오',
    });
  }

  private likeStatus = false;

  private totalLike = 0;

  private likeList = [];

  private totalReview = 0;

  private handleClickLike(review: any) {
    likeReview(review.idx).then(() => {
      if (review.likeStatus) {
        this.$message.success('리뷰 좋아요를 취소했습니다.');
        review.likeStatus = false;
        review.likeCount -= 1;
      } else {
        this.$message.success('리뷰 좋아요를 등록했습니다.');
        review.likeStatus = true;
        review.likeCount += 1;
      }
      getReservationReviewMypageTotal().then((res) => {
        this.totalReview = res.data.totalReview;
        this.totalLike = res.data.totalLike;
      });
    }).catch(() => {
      this.$message.error('요청에 실패했습니다.');
    });
    getReservationReviewMypageTotal().then((res) => {
      this.totalReview = res.data.totalReview;
      this.totalLike = res.data.totalLike;
    });
  }

  private handleCancelLike(like: any, index: number) {
    likeReview(like.idx).then(() => {
      this.$message.success('리뷰 좋아요를 취소했습니다.');
      this.likeList.splice(index, 1);
      getReservationReviewMypageTotal().then((res) => {
        this.totalReview = res.data.totalReview;
        this.totalLike = res.data.totalLike;
      });
    }).catch(() => {
      this.$message.error('요청에 실패했습니다.');
    });
  }

  private getPrivateData() {
    const privateData = this.review.likeCount;
    return privateData;
  }

  private isExpanded = false;

  private shortenedIntroduction() {
    return this.stay.introduction && this.stay.introduction.length > 200
      ? `${this.stay.introduction.slice(0, 200)}...`
      : this.stay.introduction;
  }

  private toggleExpanded() {
    this.isExpanded = !this.isExpanded;
  }

  // 슬라이드 현재페이지 숫자
  private carouselChange(index: number): void {
    if (this.stay.images.length <= index) {
      this.currentIndex -= 1;
    } else {
      this.currentIndex = index;
    }
  }

  private isMore = false;

  private ShowButton() {
    let str = this.stay.introduction;
    if (str === null) str = '';
    const introduction = str;
    const introductionLength = introduction.length;
    const windowWidth = this.getWindowWidth();

    if (windowWidth >= 1024) {
      return this.$data.informationStatus !== 'expanded' && introductionLength >= 450;
    }
    return this.$data.informationStatus !== 'expanded' && introductionLength >= 150;
  }

  private getWindowWidth(): number {
    return window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
  }

  private ShowArrowButton(): boolean {
    const tagsLength = this.$data.stay.tags.length;
    return this.getWindowWidth() >= 1024 ? tagsLength > 10 : tagsLength > 4;
  }

  private handleKakaoShare() {
    const baseUrl = process.env.VUE_APP_TARGET;
    const shareUrl = window.location.href;
    (window as any).Kakao.cleanup();
    (window as any).Kakao.init(process.env.VUE_APP_KAKAO_APP_KEY);
    (window as any).Kakao.Link.sendDefault({
      objectType: 'feed',
      content: {
        title: this.stay.name,
        description: this.stay.introduction,
        imageUrl: this.stay.images.length > 0 ? `${baseUrl}/api/attached-file/${this.stay.images[0]}` : `${baseUrl}/favicon.ico`,
        link: {
          mobileWebUrl: shareUrl,
          webUrl: shareUrl,
        },
      },
      social: {
        likeCount: 0,
        commentCount: 0,
        sharedCount: 0,
      },
      buttons: [
        {
          title: '보러가기',
          link: {
            mobileWebUrl: shareUrl,
            webUrl: shareUrl,
          },
        },
      ],
    });
  }

  private getRemainRoom() {
    let remainRoom = 0;
    this.roomList.forEach((room: any) => {
      if (room.usable && !room.closeStatus && room.remainRoom > 0) remainRoom += 1;
    });
    return remainRoom;
  }

  private handleRoomClick(room: any) {
    if (room.phoneInquiryStatus === true) {
      (window as any).location.href = `tel:${this.stay.inquiryTel}`;
    } else {
      this.$router.push({ name: 'RoomDetail', params: { roomIdx: room.idx, inquiryTel: this.stay.inquiryTel }, query: { ...this.$route.query, ...this.formQuery } });
    }
  }
}
